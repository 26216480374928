import * as React from 'react'
import styled, { Props } from '../theme'

const DocumentStyled = styled.div`
  width: 100%;

  object {
    width: 100%;
    height: 350px;
  }
`

interface DocumentProps extends Props {
  data: {
    src: string
  }
}

export default (props: DocumentProps) => (
  <DocumentStyled className={props.className}>
    <object data={props.data.src} type="application/pdf">
      <p>No se puede ver el documento, este navegador no soporta PDFs.</p>
    </object>
  </DocumentStyled>
)
