import * as React from 'react'
import Image from './Image'

interface GalleryProps {
  data: Array<{
    src: string
  }>
}

class Gallery extends React.Component<GalleryProps> {
  render() {
    return (
      <div>
        {this.props.data.map((image, index) => (
          <Image data={{src: image.src}} key={index} />
        ))}
      </div>
    )
  }
}

export default Gallery