import React from "react";
import { ThemeProvider, ThemeInterface } from "./";
import defaultTheme from "./defaultTheme";

class Theme extends React.Component<{}, { theme?: ThemeInterface }> {
  constructor(props: any) {
    super(props);
    this.state = { theme: defaultTheme };
    this.launcherMessageHandler = this.launcherMessageHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener("message", this.launcherMessageHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.launcherMessageHandler, false);
  }

  launcherMessageHandler(e: MessageEvent) {
    switch (e.data.type) {
      case "WIDGET/THEME":
        this.setState({ theme: e.data });
    }
  }

  render() {
    return (
      <ThemeProvider theme={this.state.theme}>
        {this.props.children}
      </ThemeProvider>
    );
  }
}

export default Theme;
