import * as React from 'react'
import styled, { Props, keyframes } from '../theme'

import Text from '../components/Text'
import Image from '../components/Image'
import Gallery from '../components/Gallery'
import Video from '../components/Video'
import Buttons from '../components/Buttons'
import Avatar from './Avatar'
import Document from './Document'
import { Message } from '../types'
const LoanSimulator = React.lazy(() => import('../components/LoanSimulator'));

type Position = 'left' | 'right'

const fadeLeft = keyframes`
  from {
    margin-left: -80px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
`

const fadeRight = keyframes`
  from {
    margin-right: -80px;
    opacity: 0;
  }
  to {
    margin-right: 0px;
    opacity: 1;
  }
`

const BubbleContent = styled<{ position: Position }, 'div'>('div')`
  display: flex;
  flex-direction: column;
  padding: 0 .5rem;
  max-width: 100%;
  align-items: ${p => p.position === 'left' ? 'flex-start' : 'flex-end'};

  margin: ${p => p.theme.bubble.margin};
  padding-right: ${p => p.position === 'left' ? '2rem' : '0'};
  padding-left: ${p => p.position === 'right' ? '2rem' : '0'};

  div[class^=bubble-item] {
    max-width: 100%;
    min-width: 3rem;
    min-height: auto;
    height: auto;
    /* Hack IE11: fix the height the buble message */
    display: table-cell !important;
    display: flex-box !important;
    padding: ${p => p.theme.bubble.padding};
    background: ${p => p.theme.bubble[p.position].background};
    color: ${p => p.theme.bubble[p.position].color};
    position: relative;
    z-index: 1;
    box-shadow: 0 3px 0 #00000020;
    border-radius: ${p => p.theme.bubble.radius};
  }

  div[class^=bubble-item-left] {
    animation: ${fadeLeft} 400ms ease normal;
  }

  div[class^=bubble-item-right] {
    animation: ${fadeRight} 400ms ease normal;
  }

  div[class=bubble-item-left] {
    border-bottom-left-radius: 0;
  }

  div[class=bubble-item-right] {
    border-bottom-right-radius: 0;
  }

  .bubble-item-left {
    :before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: -4px;
      bottom: 0;
      border-style: solid;
      border-width: 5px 5px 0 0;
      border-color: transparent ${p => p.theme.bubble[p.position].background};
      z-index: -1;
    }
  }

  .bubble-item-right {
    :before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      right: -4px;
      bottom: 0;
      border-style: solid;
      border-width: 5px 0 0 5px;
      border-color: transparent ${p => p.theme.bubble[p.position].background};
      z-index: -1;
    }
  }
`

interface BubbleProps extends Props {
  previousMessage: Message
  message: Message
  nextMessage: Message
  handleUserAction: (e: any) => void
}

export default class Bubble extends React.Component<BubbleProps> {
  shouldComponentUpdate(nextProps: BubbleProps) {
    return this.props.message !== nextProps.message
  }

  position = (): Position => this.props.message.fromServer ? 'left' : 'right'
  triangle = (): string => {
    const { message, nextMessage } = this.props
    if (nextMessage) {
      return message.fromServer !== nextMessage.fromServer ? '' : '-no-triangle'
    }

    return ''
  }

  showAvatar = (): boolean => {
    const { message, previousMessage } = this.props

    if (!message.fromServer) { return false }
    if (!previousMessage) { return true }
    if (message.agentAvatar !== previousMessage.agentAvatar) { return true }
    return false
  }

  renderServerMessage() {
    const message = this.props.message as Message<'server'>

    switch (message.type) {
      case 'text':
        return <Text data={message.data} />
      case 'image':
        return <Image data={message.data} />
      case 'gallery':
        return <Gallery data={message.data.slice()} />
      case 'video':
        return <Video data={message.data} />
      case 'buttons':
        return (
          <Buttons
            timestamp={message.timestamp}
            data={message.data}
            onClick={this.props.handleUserAction}
          />
        )
      case 'loan-simulator':
        return (
          <React.Suspense fallback={<div>Loading...</div>}>
            <LoanSimulator data={message.data} />
          </React.Suspense>
        );
      case 'document':
        return <Document data={message.data} />
      default:
        return ''
    }
  }

  renderClientMessage() {
    const message = this.props.message as Message<'client'>

    switch (message.type) {
      case 'text':
        return <Text data={message.data} />
      default:
        return ''
    }
  }

  render() {
    const position = this.position()
    const triangle = this.triangle()

    return (
      <BubbleContent
        className={this.props.className}
        position={position}
      >
        {this.showAvatar() &&
          <Avatar
            name={this.props.message.agentName}
            avatar={this.props.message.agentAvatar}
          />
        }
        <div className={`bubble-item-${position}${triangle}`}>
          {position === 'left' && this.renderServerMessage()}
          {position === 'right' && this.renderClientMessage()}
        </div>
      </BubbleContent>
    )
  }
}
