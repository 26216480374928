import * as React from 'react'

import PreChatForm from '../models/preChatForm'

interface PreChatProps {
  preChatForm: PreChatForm
  handleSuccess: any
}

// TODO: this component must have a form acording to
// prechatForm.fields. The info entered by the user must
// be validated and sent to the parent component App to be
// sent to the server
class PreChat extends React.Component<PreChatProps, any> {
  handleFormSubmit = () => {
    // TODO: send result to parent
    this.props.handleSuccess({})
  }

  render() {
    return (
      <div>
        <div>{this.props.preChatForm.description}</div>
        <button onClick={this.handleFormSubmit}>Login</button>
      </div>
    )
  }
}

export default PreChat
