import * as React from 'react'
import styled, { Props } from '../theme'

const ImageStyled = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`

interface ImageProps extends Props {
  data: {
    src: string
  }
}

export default (props: ImageProps) => (
  <ImageStyled className={props.className}>
    <img src={props.data.src} />
  </ImageStyled>
)
