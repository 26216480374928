import * as React from 'react'
const HTMLReactParser = require('html-react-parser')
import styled, { Props, keyframes } from '../theme'
import { ClientMessage } from '../types'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ButtonBase = styled.button`
  display: block;
  cursor: pointer;
  outline: none;
  border: none;
  background: ${props => props.theme.buttons.background};
  font-weight: 700;
  color: ${props => props.theme.buttons.color};
  box-sizing: border-box;
  padding: 8px 10px;
  margin: 3px auto;
  font-size: 0.9rem;
  height: auto;
  width: 100%;
  animation: ${appear} 500ms ease-in normal;
  text-align: center;
`

const Button = styled(ButtonBase)`
  :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  :hover, :focus {
    box-shadow: inset 0 0 100px 0 rgba(0, 0, 0, 0.1);
  }
`

const ButtonSelected = styled(ButtonBase)`
  pointer-events: none;
  border-radius: 5px;
  box-shadow: inset 0 0 100px 0 rgba(0, 0, 0, 0.1);
`

interface Option {
  label: string
  value?: any
  command?: any
}

export interface ButtonsProps extends Props {
  onClick: any
  timestamp: number
  data: {
    text?: string;
    name: string;
    selected?: string;
    options: Option[];
  }
}

interface ButtonsState {
  collapse: boolean
}

class Buttons extends React.Component<ButtonsProps, ButtonsState> {
  static maxButtons: number = 4

  constructor(props: ButtonsProps) {
    super(props)
    this.state = {
      collapse: props.data.options.length >= Buttons.maxButtons + 2
    }
  }

  handleClick = (option: Option) => {
    const message: ClientMessage<'button'> = {
      type: 'button',
      timestamp: this.props.timestamp,
      data: {
        name: this.props.data.name,
        label: option.label,
        value: option.value,
      }
    }

    if (option.command) {
      message.data.command = option.command
    }

    this.props.onClick(message)
  }

  showAll = () => this.setState({ collapse: false })

  renderOptions = () => {
    const { data } = this.props
    const { collapse } = this.state

    return (
      <div>
        {data.options
          .slice(0, collapse ? Buttons.maxButtons : data.options.length)
          .map((option, i) =>
            <Button
              key={i}
              onClick={this.handleClick.bind(this, option)}
            >{option.label}
            </Button>
          )
        }
        {collapse &&
          <Button onClick={this.showAll}>VER MÁS...</Button>
        }
      </div>
    )
  }

  render() {
    const { data } = this.props
    return (
      <div className="buttons">
        {data.text && (
          <div>{HTMLReactParser(this.props.data.text, {})}</div>
        )}
        {data.selected ?
          <ButtonSelected>{data.selected}</ButtonSelected> :
          this.renderOptions()
        }
      </div>
    )
  }
}

export default Buttons
