import * as React from 'react'
const HTMLReactParser = require('html-react-parser')

interface TextProps {
  data: {
    text: string;
  }
}

class Text extends React.Component<TextProps> {
  render() {
    return <span>{HTMLReactParser(this.props.data.text, {})}</span>
  }
}

export default Text
