import * as React from 'react'
import styles from './Loading.module.css'

const Loading: React.FC<{}> = () => (
  <div className={styles.loading}>
    <div className={`${styles.loader} loader`}>Loading...</div>
  </div>
)

export default Loading
