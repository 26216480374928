import * as React from 'react'
import SendSvg from '../media/Send'
import styled, { Props } from '../theme'

interface InputFormStyledProps {
  disabled: boolean
}

const InputFormStyled = styled<InputFormStyledProps, 'form'>('form')`
  display: flex;
  width: 100%;
  border-top: 1px solid #dfe7ef;
  padding: ${p => p.theme.sendform.padding};

  .input-message {
    font-family: inherit;
    flex: 2;
    font-size: 16px;
    color: ${p => p.theme.sendform.input.color};
    background: ${p => p.theme.sendform.input.background};
    resize: none;
    transition: border .3s ease-out;
    border: ${p => p.theme.sendform.input.border};
    border-radius: ${p => p.theme.sendform.input.radius};
    overflow: hidden;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    min-height: 0;
    max-height: 5.8rem;
    align-items: flex-end;
    transition: border 200ms ease;

    :focus {
      outline: 0;
      border-color: #828c99;
    }

    :hover {
      outline: 0;
      border-color: #828c99;
    }

    ::placeholder {
      color: #9197a3;
      font-size: 14;
    }
  }

  .send-button {
    flex: 0;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0;
    margin: 0 0 0 0.5rem;
    background: transparent;
    opacity: 1;
    transition: opacity 200ms ease;

    :hover {
      opacity: 0.7;
    }
  }
`
interface InputFormProps extends Props {
  disabled: boolean
  focus: boolean
  onSubmit(value: string, reset: Function): void
  onFocus(): void
}

interface InputFormState {
  value: string
}

export default class InputForm extends React.Component<InputFormProps, InputFormState> {

  state: InputFormState = {
    value: ''
  }

  refInputFocus: React.RefObject<HTMLTextAreaElement>

  constructor(props: InputFormProps) {
    super(props)

    this.refInputFocus = React.createRef()
  }

  resetForm = () => {
    this.setState({ value: '' }, this.autoresize)
  }

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const { value } = this.state
    if (value.trim()) {
      this.props.onSubmit(value.trim(), this.resetForm)
    }
    this.forceFocus()
  }

  onKeyDown = (event: any) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      this.onSubmit(event)
    }
  }

  autoresize = () => {
    const el = this.refInputFocus.current
    if (!el) {
      return
    }

    const computedStyle = getComputedStyle(el)
    const borderTop = computedStyle.getPropertyValue('border-top-width').replace('px', '')
    const borderBottom = computedStyle.getPropertyValue('border-bottom-width').replace('px', '')
    el.style.cssText = 'height: auto'
    const height = el.scrollHeight + parseInt(borderTop, 10) + parseInt(borderBottom, 10)
    el.style.cssText = `height: ${height}px`
  }

  onChange = (event: any) => {
    this.setState({ value: event.target.value }, this.autoresize)
  }

  forceFocus = () => this.refInputFocus.current!.focus()

  render(): JSX.Element {
    return (
      <InputFormStyled
        className={this.props.className}
        disabled={this.props.disabled}
        onSubmit={this.onSubmit}
      >
        <textarea
          ref={this.refInputFocus}
          className="input-message"
          name="text"
          rows={1}
          value={this.state.value}
          onChange={this.onChange}
          disabled={this.props.disabled}
          autoFocus={this.props.focus}
          placeholder={this.props.disabled ? 'Conectando...' : 'Escriba su mensaje'}
          onKeyDown={this.onKeyDown}
          onFocus={this.props.onFocus}
        />
        <button
          className="send-button"
          autoFocus={false}
          onFocus={this.forceFocus}
          type="submit"
        >
          <SendSvg />
        </button>
      </InputFormStyled>
    )
  }
}