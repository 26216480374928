import SockJS from "sockjs-client";
import { ServerMessage, ClientMessage } from "../types";

/** TODO:
 * - Pasar a modo inactivo cuando el usuario no usa el widget o pasa de pestaña
 * - Evitar que el usuario envie demasiados mensajes seguidos
 * - Maximo numero de reconecciones a 3
 */

class SockJSBus {
  public onReady!: () => void;
  public onOpen!: () => void;
  public onClose!: () => void;
  public onMessage!: (message: ServerMessage) => void;

  private socket?: WebSocket;
  private channelId: string = "";
  private chatId: string = "";
  private socketReady = false;
  private pingLimit = 3;
  private pingInterval: any;
  private pingMiss = 0;
  private retryTime = 2000;

  public init(channelId: string, chatId: string) {
    this.channelId = channelId;
    this.chatId = chatId;

    const uri = `https://${process.env.REACT_APP_SOCKET_URL}/wsocket?clientId=${this.channelId}&chatId=${this.chatId}`;

    if (this.socket) {
      delete this.socket;
    }

    this.socket = new SockJS(uri, {}, { sessionId: 32 });

    this.socket.onopen = () => {
      vchat.debug(
        "%c SockJs open ",
        "background: green; color: white; font-weight: bold"
      );
      vchat.debug(
        `%c channelId: ${this.channelId} `,
        "background: green; color: white; font-weight: bold"
      );
      vchat.debug(
        `%c ChatId: ${this.chatId} `,
        "background: green; color: white; font-weight: bold"
      );
      this.startPing();
      this.retryTime = 2000;

      if (this.onOpen) {
        this.onOpen();
      }

      if (this.onReady) {
        this.socketReady = true;
        this.onReady();
      }
    };

    this.socket.onmessage = (message) => this.onSocketMessage(message);

    this.socket.onerror = (error: any) => {
      vchat.debug("%c Error: ", "color: red; font-weight: bold", error);
    };

    this.socket.onclose = () => {
      vchat.debug(
        "%c SockJs close ",
        "background: red; color: white; font-weight: bold"
      );

      this.socketReady = false;

      this.disconnect();
      this.stopPing();
      this.reconnect();
    };
  }

  public isReady(): boolean {
    return this.socketReady;
  }

  public sendMessage(message: ClientMessage): void {
    const payload = {
      ...message,
      timestamp: Date.now(),
      channelId: this.channelId,
      chatId: this.chatId,
      fromServer: false,
    };
    try {
      if (this.socket) {
        this.socket.send(JSON.stringify(payload));
        vchat.debug("%c Sent: ", "color: green; font-weight: bold", payload);
      }
    } catch (error) {
      vchat.debug("%c Sent: ", "color: red; font-weight: bold", error);
    }
  }

  private ping() {
    const pingMessage = {
      type: "ping",
      chatId: this.chatId,
    };
    if (this.socket) {
      this.socket.send(JSON.stringify(pingMessage));
    }
  }

  private startPing() {
    if (this.pingInterval) {
      return;
    }

    const cb = () => {
      this.pingMiss += 1;
      if (this.pingMiss >= this.pingLimit) {
        clearInterval(this.pingInterval);
        this.pingInterval = undefined;
        this.pingMiss = 0;
        this.disconnect();
        this.reconnect();
      }
      this.ping();
    };
    this.pingInterval = setInterval(cb, 8000 + Math.random() * 2000);
  }

  private stopPing() {
    clearInterval(this.pingInterval);
  }

  private disconnect() {
    this.socketReady = false;
    this.onClose();
  }

  private reconnect() {
    vchat.debug(
      `%c Connecting in: ${this.retryTime | 0}ms`,
      "color: steelblue; font-weight: bold"
    );

    setTimeout(() => {
      if (!this.socketReady) {
        this.init(this.channelId, this.chatId);
        this.retryTime *= 1.2;
      }
    }, this.retryTime);
  }

  private onSocketMessage(message: MessageEvent) {
    try {
      const data = JSON.parse(message.data);

      const m = data as ServerMessage;
      m.fromServer = true;

      if (data.type !== "pong") {
        this.onMessage(m);
        vchat.debug("%c Received:", "color: steelblue; font-weight: bold", m);
      }
      this.pingMiss = 0;
    } catch (e) {
      vchat.debug(
        "%c Error on received:",
        "color: red; font-weight: bold",
        e,
        message
      );
    }
  }
}

export default SockJSBus;
