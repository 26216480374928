import * as styledComponents from "styled-components";

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  ThemeInterface
>;

type Margin = {
  margin?: string;
};

type Padding = {
  padding?: string;
};

type Radius = {
  radius?: string;
};

type Border = {
  border?: string;
};

interface Spacing extends Margin, Padding {}

type Color = {
  color?: string;
  background?: string;
};

export interface ThemeInterface {
  primary: string;
  secondary: string;
  light: string;
  header: Padding & Color;
  body: Color;
  bubble: {
    left: Color;
    right: Color;
  } & Radius &
    Spacing;
  sendform: {
    send: Color;
    input: Radius & Border & Color;
  } & Padding;
  buttons: Spacing & Color;
}

export interface Props {
  className?: string;
}

export default styled;
export { css, keyframes, ThemeProvider };
