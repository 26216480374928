import * as React from 'react'
import CloseSvg from '../media/Close'
import styled, { Props } from '../theme'

const HeaderStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${p => p.theme.header.background};
  color: ${p => p.theme.header.color};
  padding: ${p => p.theme.header.padding};
  font-weight: bold;

  .title {
    color: inherit;
  }
  .subtitle {
    color: inherit;
    display: block;
    opacity: 0.5;
    font-size: 8px;
    text-transform: uppercase;
    text-decoration: none;
  }
  .close-button {
    height: 32px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
  }
`

interface HeaderProps extends Props {
  handleClose: any
  title?: string
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <HeaderStyled className={props.className}>
      <div className="title">
        {props.title || 'Bienvenido'}
        <a
          className="subtitle"
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.vertical.com.co/"
        >
          POWERED BY VERTICAL TECHNOLOGIES
        </a>
      </div>
      <button
        aria-label="Close"
        className="close-button"
        onClick={props.handleClose}
      >
        <CloseSvg/>
      </button>
    </HeaderStyled>
  )
}

export default Header
