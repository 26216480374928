import { ThemeInterface } from ".";

const theme: ThemeInterface = {
  primary: "#e9e9eb",
  secondary: "#fcd62d",
  light: "#b0b0b0",
  header: {
    background: "#2d56a6",
    color: "#fdfeff",
    padding: ".5rem .5rem .5rem 1rem",
  },
  body: {
    color: "#737373",
  },
  bubble: {
    radius: "10px",
    margin: "0.5rem 1rem",
    padding: "0.5rem",
    left: {
      background: "#e9edf6",
      color: "#4a4c4b",
    },
    right: {
      background: "#2d56a6",
      color: "#fefefe",
    },
  },
  sendform: {
    padding: ".5rem",
    send: {
      color: "#2d56a6",
    },
    input: {
      color: "#16171a",
      background: "#fff",
      border: "none",
      radius: "10px",
    },
  },
  buttons: {
    background: "#17abe6",
    color: "#fefefe",
  },
};

export default theme;
