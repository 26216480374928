import * as React from 'react'
import styled, { Props } from '../theme'

const VideoStyled = styled.div`
  width: 100%;

  video {
    width: 100%;
  }
`

interface VideoProps extends Props {
  data: {
    src: string
  }
}

export default (props: VideoProps) => (
  <VideoStyled className={props.className}>
    <video src={props.data.src} controls autoPlay />
  </VideoStyled>
)
