import * as React from 'react'
import styled, { Props } from '../theme'

const NoAvatar = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 40"
      aria-hidden="true"
    >
      <path d="M 20,0 C 8.9516129,0 0,8.95161 0,20 0,31.04839 8.9516129,40 20,40 31.048387,40 40,31.04839 40,20 40,8.95161 31.048387,0 20,0 Z m 0,7.74194 c 3.919355,0 7.096774,3.17741 7.096774,7.09677 0,3.91935 -3.177419,7.09677 -7.096774,7.09677 -3.919355,0 -7.096774,-3.17742 -7.096774,-7.09677 0,-3.91936 3.177419,-7.09677 7.096774,-7.09677 z m 0,27.74193 c -4.733871,0 -8.975806,-2.14516 -11.8145161,-5.5 1.516129,-2.85484 4.4838711,-4.82258 7.9435481,-4.82258 0.193549,0 0.387097,0.0323 0.572581,0.0887 C 17.75,25.5887 18.846774,25.80644 20,25.80644 c 1.153226,0 2.258065,-0.21774 3.298387,-0.55645 0.185484,-0.0564 0.379032,-0.0887 0.572581,-0.0887 3.459677,0 6.427419,1.96774 7.943548,4.82258 -2.83871,3.35484 -7.080645,5.5 -11.814516,5.5 z" />
    </svg>
  )
}

export default styled(NoAvatar)`
  width: 40px;
  height: 40px;
  margin-right: .5rem;
  margin-bottom: .5rem;

  path {
    fill: ${props => props.theme.light};
  }
`