import * as React from 'react'
import styled, { Props } from '../theme'

const Send = ({ className }: Props) => {
  return (
    <svg
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.414"
      aria-labelledby="title"
      viewBox="0 0 32 32"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <path d="M16.044,15.012c-0.005,-0.104 -0.071,-0.205 -0.198,-0.232l-7.45,-1.579c-0.231,-0.049 -0.396,-0.253 -0.396,-0.489l0,-5.712c0,-0.73 0.698,-1.159 1.419,-0.908c4.295,1.497 12.081,5.408 15.616,8.025c0.34,0.252 0.515,0.573 0.52,0.895c-0.005,0.323 -0.18,0.644 -0.52,0.896c-3.535,2.617 -11.321,6.868 -15.616,8.365c-0.721,0.251 -1.419,-0.178 -1.419,-0.908l0,-6.052c0,-0.236 0.165,-0.44 0.396,-0.489l7.45,-1.579c0.127,-0.027 0.193,-0.129 0.198,-0.233Z" />
    </svg>
  )
}

export default styled(Send)`
  height: 32px;
  width: 32px;

  path {
    fill: ${props => props.theme.sendform.send.color}
  }
`