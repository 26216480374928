import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Theme from "./theme/Theme";

const root = document.getElementById("root");

if (!window.vchat) {
  window.vchat = {
    enableDebug: false,
    debug: (...args: any[]) => {
      if (window.vchat.enableDebug) {
        console.log(...args);
      }
    },
  };
}

ReactDOM.render(
  <Theme>
    <App />
  </Theme>,
  root
);
