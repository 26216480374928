import * as React from 'react'
import styles from './Offline.module.css'

const Offline = () => (
  <div className={styles.offline}>
    <div className={styles.inner}>
      Lo sentimos, en este momento el chat no se encuentra disponible. Por favor
      intente más tarde.
    </div>
  </div>
)

export default Offline
