/* tslint:disable */
import ConfigChat from "../models/configChat";
import PreChatForm from "../models/preChatForm";
import { getStorage } from "../utils/storageWrapper";

// @ts-ignore
const messages: any[] = [
  {
    type: "text",
    data: {
      text: "Have a <strong>nice day.</strong>",
    },
    timestamp: 1536168013,
    agentName: "Bot Agent",
    agentAvatar: "https://picsum.photos/100/100?image=64",
    fromServer: true,
  },
  {
    type: "text",
    data: {
      text: "Thanks, I need your support.",
    },
    timestamp: 1536168014,
    fromServer: false,
  },
  {
    type: "text",
    data: {
      text: "I'm having problems with my account.",
    },
    timestamp: 1536168014,
    fromServer: false,
  },
  {
    type: "text",
    data: {
      text: "Please let me know what is your email.",
    },
    timestamp: 1536168013,
    agentName: "Bot Agent",
    agentAvatar: "",
    fromServer: true,
  },
  {
    type: "buttons",
    data: {
      name: "Buttons name",
      text: "Choose an option",
      options: [
        {
          label: "Option one",
          value: "1",
        },
        {
          label: "Text option",
          value: "Chosen option",
        },
        {
          label: "Go to another page",
          command: {
            name: "redirect",
            payload: ["/redirectpage.html", "element-to-scroll"],
          },
        },
      ],
    },
    timestamp: 1536168013,
    agentName: "Bot Agent",
    agentAvatar: "",
    fromServer: true,
  },
];

// @ts-ignore
const emptyMessages: any[] = [];

// @ts-ignore
const prechatForm: PreChatForm = {
  description: "Please fill this form to start",
  fields: [
    {
      name: "name",
      label: "User name",
      required: true,
    },
    {
      name: "email",
      label: "Email",
    },
  ],
};

export const postConfig = (url: string, channelId: string) => {
  return new Promise<ConfigChat>((resolve, reject) => {
    setTimeout(() => {
      resolve({
        messages: getStorage("messages") || [],
        // messages: emptyMessages
        // prechatForm
      });
    }, 50);
  });
};
