import * as React from 'react'
import InputForm from '../components/InputForm'
import styles from './Chat.module.css'
import Bubble from '../components/Bubble'
import autoScroll from '../utils/autoScroll'
import { ClientMessage } from '../types'
const LoanSimulator = React.lazy(() => import('../components/LoanSimulator'))

interface ChatProps {
  windowOpen: boolean
  messages: any[]
  handleUserMessage: any
  disabled: boolean
}

interface ChatState {
  user?: any
  messages: any[]
  source?: any
  area: string
  textmessage: string
}

class Chat extends React.Component<ChatProps, ChatState> {
  state: ChatState = {
    messages: [],
    area: '',
    textmessage: ''
  }

  refBody: React.RefObject<any> = React.createRef()
  autoScroll: any

  componentDidMount() {
    this.autoScroll = autoScroll(this.refBody.current)
    this.autoScroll.init()
  }

  handleUserInputSubmit = (value: string, resetForm: () => void) => {
    const message: ClientMessage<'text'> = {
      type: 'text',
      data: {
        text: value.trim()
      }
    }
    this.props.handleUserMessage(message)
    resetForm()
  }

  onInputFocus = () => {
    this.autoScroll.scrollBottom()
  }

  render() {
    return (
      <>
        <div
          ref={this.refBody}
          className={`scrolls ${styles.chat}`}
        >
          {this.props.messages.map((message: any, i, messages) => (
            <Bubble
              key={i}
              previousMessage={messages[i - 1]}
              message={message}
              nextMessage={messages[i + 1]}
              handleUserAction={this.props.handleUserMessage}
            />
          ))}
        </div>
        <InputForm
          disabled={this.props.disabled}
          onSubmit={this.handleUserInputSubmit}
          onFocus={this.onInputFocus}
          focus={this.props.windowOpen}
        />
      </>
    )
  }
}

export default Chat
