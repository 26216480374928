import * as React from "react";

import { postConfig } from "./services/api.mock";
import SockJSBus from "./services/sockJSBus";
import uuid from "./utils/uuidv4";
import { getStorage, setStorage } from "./utils/storageWrapper";

import Header from "./components/Header";
import Loading from "./views/Loading";
import PreChat from "./views/PreChat";
import Chat from "./views/Chat";
import Offline from "./views/Offline";
import styles from "./App.module.css";
import { ServerMessage, Message, ClientMessage } from "./types";

enum View {
  Loading,
  PreChat,
  Chat,
  Offline,
}

interface AppState {
  currentView: View;
  messages: Array<Message>;
  preChatForm?: any; // TODO: remove from state?
  socketReady: boolean;
  agentName?: any;
  isOpen: boolean;
}

class App extends React.Component<{}, AppState> {
  state: AppState = {
    socketReady: false,
    currentView: View.Loading,
    messages: [],
    agentName: "",
    isOpen: false,
  };

  private socket!: SockJSBus;
  private channelId?: string;
  private chatId!: string;
  private origin?: string;
  private source?: any;

  componentDidMount() {
    // Listen messages from the button on the parent page
    window.addEventListener("message", this.launcherMessageHandler, false);

    // Get the chatId from local or session storage or create a new one if it doesn't exist
    this.chatId = getStorage("chatId");
    if (!this.chatId) {
      this.chatId = uuid();
      setStorage("chatId", this.chatId);
    }

    this.socket = new SockJSBus();
    // Get the chat configuration and set state accordingly
    postConfig(process.env.REACT_APP_API_URL + "getConfig", this.chatId).then(
      (config) => {
        // TODO: previous messages will be provided by the server in the future
        const { messages } = config;

        this.setState({
          currentView: View.Chat,
          preChatForm: config.preChatForm,
          agentName: this.agentName(messages[messages.length - 1]),
          messages,
        });
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.launcherMessageHandler, false);
  }

  launcherPostMessage = (type: string, data?: any) => {
    vchat.debug("vchat: > launcher post message", { type, ...data });

    if (this.source && this.origin) {
      this.source.postMessage(
        {
          ...data,
          type,
        },
        this.origin
      );
    } else {
      throw new Error(
        `chat: ${type} ${this.origin} post message couldn't be sent`
      );
    }
  };

  launcherMessageHandler = (e: MessageEvent): void => {
    // Ignore react devtools messages
    if (e.data.source && e.data.source.includes("react-devtools")) {
      return;
    }

    vchat.debug("vchat: < launcher message", e.data);

    switch (e.data.type) {
      case "VCHAT/INIT":
        this.source = e.source;
        this.origin = e.origin;
        this.launcherPostMessage("VCHAT/CREATED");
        break;
      case "VCHAT/DEBUG":
        vchat.enableDebug = e.data.enableDebug;
        break;
      case "WINDOW/SHOW":
        this.setState({ isOpen: true });
        if (!this.socket.isReady()) {
          this.source = e.source;
          this.origin = e.origin;
          this.channelId = e.data.channelId;
          this.ready();
        }
        break;
      case "WINDOW/HIDE":
        this.setState({ isOpen: false });
        break;
      default:
        break;
    }

    // Process messages sent from the parent page
    if (e.data.type === "VCHAT/COMMAND") {
      if (!this.socket.isReady()) {
        vchat.debug("%c sockjs isn't ready", "color: red; font-weight: bold");
        return;
      }
      this.socket.sendMessage({
        type: "command",
        data: {
          name: e.data.name,
          innerCommand: !!e.data.innerCommand,
          payload: e.data.payload,
        },
      } as ClientMessage<"command">);
    }
  };

  /**
   * This method tells the button in the container page
   * that all is ready to start the chat.
   */
  ready = (): void => {
    // Start the EventBus to send and receive messages
    if (!this.channelId) {
      throw new Error(
        "chat: verify that VChat instance was created with a valid channelId."
      );
    }

    this.socket.onReady = () => {
      this.launcherPostMessage("VCHAT/READY");
    };

    this.socket.onMessage = this.serverReceiver;

    this.socket.onOpen = () => {
      this.setState({ currentView: View.Chat, socketReady: true });
    };

    this.socket.onClose = () => {
      this.setState({ currentView: View.Chat, socketReady: false });
    };

    this.socket.init(this.channelId, this.chatId);
  };

  agentName = (message?: Message) => {
    if (message && message.fromServer && message.agentName) {
      return message.agentName;
    }
    return this.state.agentName || "Bienvenido";
  };

  restartAgent = () => {
    this.setState({ messages: [] }, () => {
      setStorage("messages", []);
      const messageMenu: ClientMessage<"text"> = {
        type: "text",
        data: {
          text: "@menu",
        },
      };
      if (this.socket && this.socket.isReady()) {
        this.socket.sendMessage(messageMenu);
      }
    });
  };

  /**
   * Receive messages from the server
   */
  serverReceiver = (message: ServerMessage) => {
    if (message.type === "command") {
      this.triggerClientCommand(message.data.name, message.data.payload);
    } else {
      const newMessages = [...this.state.messages, message];
      this.setState({
        messages: newMessages,
        agentName: this.agentName(message),
      });

      setStorage("messages", newMessages);
    }
  };

  /**
   * Send commands to the page
   */
  triggerClientCommand = (command: string, payload: any) => {
    this.launcherPostMessage("HOST_API/COMMAND", {
      type: "HOST_API/COMMAND",
      command,
      payload,
    });
  };

  /**
   * Send a message to the button to close the chat window
   */
  handleClose = () => {
    this.launcherPostMessage("WINDOW/HIDE");
  };

  /**
   * Event if the user fill the pre chat form successfully
   */
  handleSuccessPrechat = (formData: {}): void => {
    this.setState({
      currentView: View.Chat,
    });
  };

  /**
   * Process messages from the user and send them to the page or the server
   */
  handleUserMessage = (message: ClientMessage): void => {
    if (!(this.socket && this.socket.isReady())) {
      vchat.debug("%c sockjs isn't ready", "color: red; font-weight: bold");
      return;
    }

    if (message.type === "text") {
      const newMessages = [...this.state.messages, message];
      this.setState({
        messages: newMessages,
      });
      this.socket.sendMessage(message);
      setStorage("messages", newMessages);
    }

    if (message.type === "button") {
      const newMessages = [...this.state.messages].reverse();
      {
        const index = newMessages.findIndex(
          (m) => m.type === "buttons" && m.timestamp === message.timestamp
        );

        if (index !== -1) {
          newMessages[index] = {
            ...newMessages[index],
            data: {
              ...newMessages[index].data,
              selected: message.data.label,
            },
          } as ServerMessage<"buttons">;
        }
      }

      this.setState({
        messages: newMessages.reverse(),
      });

      setStorage("messages", newMessages);
      if (message.data.value) {
        this.socket.sendMessage(message);
      } else if (message.data.command) {
        this.triggerClientCommand(
          message.data.command.name,
          message.data.command.payload
        );
      }
    }
  };

  render() {
    let view: JSX.Element;

    switch (this.state.currentView) {
      case View.Loading:
        view = <Loading />;
        break;
      case View.PreChat:
        view = (
          <PreChat
            preChatForm={this.state.preChatForm}
            handleSuccess={this.handleSuccessPrechat}
          />
        );
        break;
      case View.Chat:
        view = (
          <Chat
            windowOpen={this.state.isOpen}
            messages={this.state.messages}
            disabled={!this.state.socketReady}
            handleUserMessage={this.handleUserMessage}
          />
        );
        break;
      case View.Offline:
        view = <Offline />;
        break;
      default:
        view = <div>Error</div>;
    }

    return (
      <div className={styles.app}>
        <Header handleClose={this.handleClose} title={this.state.agentName} />
        {view}
      </div>
    );
  }
}

export default App;
