import * as React from 'react'
import styled, { Props } from '../theme'
import NoAvatarSvg from '../media/NoAvatar'

const AvatarStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .avatar-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: .5rem;
    margin-bottom: .5rem;
    box-shadow: inset 0 0 2px white;
    background: transparent;
  }

  .avatar-name {
    font-size: 12px;
    padding: .25rem;
    font-weight: bold;
    color: ${p => p.theme.body.color};
  }
`

interface AvatarProps extends Props {
  avatar?: string
  name?: string
}

export default (props: AvatarProps) => (
  <AvatarStyled className={props.className}>
    {props.avatar ?
      <img
        className="avatar-image"
        src={props.avatar}
      />
      : <NoAvatarSvg />
    }
    <span className="avatar-name">{props.name}</span>
  </AvatarStyled>
)