const throttle = require('lodash.throttle')
// NOTE: `from` should be less than `to`
class NumberAnimation {
  from = 0
  to = 0
  increment = 200
  value = 0
  interval: any = undefined
  observer: ((value: number) => void) | undefined

  run = () => {
    if (this.interval) {
      return
    }

    const cb = () => {
      this.value += this.increment
      if (this.observer) {
        this.observer(Math.min(this.value, this.to))
      }
      if (this.value >= this.to) {
        this.stop()
      }
    }
    this.interval = setInterval(cb, 1000 / 60)
  }

  subscribe = (f: any, { from, to, velocity }: any) => {
    this.observer = f
    this.from = Math.min(from, to)
    this.to = to
    this.value = this.from
    this.increment = (this.to - this.from) / (1000 / Math.max(velocity, 60))
    this.run()
  }

  stop = () => {
    clearInterval(this.interval)
    this.interval = undefined
  }

  running = () => {
    return this.interval !== undefined
  }
}

export default function (container: HTMLElement) {
  const anim = new NumberAnimation()
  const animStart = throttle(
    () => {
      if (anim.running()) {
        anim.stop()
      }
      
      anim.subscribe(
        (value: number) => {
          container.scrollTop = value
        },
        {
          from: container.scrollTop,
          to: container.scrollHeight - container.clientHeight + 50,
          velocity: 200
        }
      )
    },
    300,
    { leading: true, trailing: true }
  )
  
  const cb = (mutations: MutationRecord[]) => {
    if (mutations.findIndex(m => m.type === 'childList') !== -1) {
      animStart()
    }
  }

  return {
    init: () => {
      const observer = new MutationObserver(cb)
      var options = { attributes: false, childList: true, subtree: true }
      observer.observe(container, options)
    },
    scrollBottom: () => {
      // do autoscroll on 0ms
      animStart()
      // do another autoscroll later of 300ms
      animStart()
    }
  }
}