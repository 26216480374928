const storageName = "vchat";

export const setStorage = (key: string, value: any) => {
  const currentStorage = sessionStorage.getItem(storageName) || "{}";
  let currentValues = JSON.parse(currentStorage) || {};
  currentValues[key] = value;
  sessionStorage.setItem(storageName, JSON.stringify(currentValues));
};

export const getStorage = (key: string): any => {
  const currentStorage = sessionStorage.getItem(storageName) || "{}";
  const currentValues = JSON.parse(currentStorage);
  if (currentValues && currentValues[key]) {
    return currentValues[key];
  } else {
    return null;
  }
};
